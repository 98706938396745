/* eslint-disable import/no-cycle */
import { add, format } from "date-fns"
import { decode } from "entities"
import { DomUtils, parseDocument } from "htmlparser2"
import { FieldValues, UseFormSetError } from "react-hook-form"

import { ITOCertificateType } from "api/resources/credentials/types"
import { ItemPrice, Product } from "api/resources/learn/types"
import { Masterclass } from "api/resources/masterclasses/types"
import { AuthData, StepsOverview } from "api/resources/users/types"
import { APIResponse, ErrorResponse } from "api/types"

import { SSO_URL, SURAASA_LEARN_APP_SCHEME, USER_TYPE } from "./constants"
import { GA } from "./googleAnalytics"
import toast from "./toast"
import { trackingService } from "./tracking"

export function parseDuration(durationString: string): number {
  const parts = durationString.split(" ")

  const days = parts.length === 2 ? parseInt(parts[0]) : 0
  const timeStr = parts.length === 2 ? parts[1] : parts[0]

  const [hours, minutes, seconds] = timeStr.split(":").map(Number)

  if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
    throw new Error(
      "Invalid duration string format. It should be 'd hh:mm:ss' or 'hh:mm:ss'."
    )
  }

  const totalSeconds =
    days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds

  return totalSeconds
}

/**
 * @deprecated use mapErrors instead
 */
export const handleErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors, message }: ErrorResponse["errors"],
  { showToast }: { showToast?: boolean } = { showToast: false }
) => {
  if (showToast && message) {
    toast.error(message)
  }

  if (fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setter(k as any, { message: Array.isArray(v) ? v[0] : v })
    }
  }
}

export function getMinimumPrice(products: Product[]): ItemPrice {
  let min = Number.MAX_SAFE_INTEGER
  let minPriceObj: ItemPrice | undefined

  const prices = products.map(x => x.price)

  for (const obj of prices) {
    if (obj.price < min) {
      min = obj.price
      minPriceObj = obj
    }
  }

  return minPriceObj || products[0].price
}

export function getPrice(
  products: Product[],
  { isIndia }: { isIndia?: boolean } = {
    isIndia: false,
  }
) {
  if (products === null) return "No Price"
  if (products.length === 0) return "No Price"

  const { currency, price } = getMinimumPrice(products)
  const priceStr = `${currency.symbol}${price.toLocaleString(
    isIndia ? "en-IN" : undefined
  )}`

  return priceStr
}

export const buildParams = (rawParams: {
  [key: string]: string | string[]
}) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(rawParams)) {
    if (!value) continue

    if (Array.isArray(value)) {
      value.forEach(v => params.append(key, v))
    } else params.append(key, value)
  }

  return params
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export type ValueOf<T> = T[keyof T]

export const isProfileComplete = (
  overview: StepsOverview & { loading: boolean }
) => {
  if (overview.loading) return false
  return (
    overview.marketingFormDataExists &&
    overview.profileDataExists &&
    overview.qualificationDataExists
  )
}

export const generateProfileCompletionURL = (config: { jobId: string }) => {
  try {
    const nextUrl = new URL(window.location.href)
    nextUrl.searchParams.append("applyingOnJobId", config.jobId)

    if (SSO_URL) {
      // const url = new URL(SSO_URL)
      // url.searchParams.append("platform", USER_TYPE)
      // url.searchParams.append("next", nextUrl.href)
      // url.searchParams.append("redirect-url", window.location.origin)
      return `${SSO_URL}/?platform=${USER_TYPE}&redirect-url=${encodeURIComponent(
        `${window.location.origin}?next=${encodeURIComponent(
          nextUrl.pathname + nextUrl.search
        )}`
      )}`
    }
  } catch (error) {
    console.error(error)
    return process.env.NEXT_PUBLIC_SSO_URL
  }
}

export const getPlatformURL = (
  platform: "sso" | "learn" | "suraasa" | "jobs" | "school",
  url: string
) => {
  switch (platform) {
    case "sso":
      return `${process.env.NEXT_PUBLIC_SSO_URL}${url}`
    case "learn":
      return `${process.env.NEXT_PUBLIC_LEARN_PLATFORM_URL}${url}`
    case "suraasa":
      return `${process.env.NEXT_PUBLIC_SURAASA_PLATFORM_URL}${url}`
    case "jobs":
      return `${process.env.NEXT_PUBLIC_JOBS_PLATFORM_URL}${url}`
    case "school":
      return `${process.env.NEXT_PUBLIC_SCHOOL_PLATFORM_URL}${url}`
    default:
      return url
  }
}

export const getSignupRedirectURL = () =>
  getPlatformURL(
    "sso",
    `/signup/?redirect-url=${encodeURIComponent(
      getPlatformURL("jobs", "/home")
    )}&platform=Student Jobs`
  )

export function checkStorageSupport() {
  if (typeof window === "undefined") return false

  try {
    localStorage.setItem("__TEST_LOCAL_STORAGE", "true")
    localStorage.removeItem("__TEST_LOCAL_STORAGE")
    sessionStorage.setItem("__TEST_SESSION_STORAGE", "true")
    sessionStorage.removeItem("__TEST_SESSION_STORAGE")
  } catch (e) {
    return false
  }
  // returns true if storages are supported
  return true
}

export const buildPhoneNumber = ({
  countryCode,
  code,
  number,
}: {
  countryCode?: string
  code?: string
  number: string
}) => {
  countryCode = code || countryCode
  if (!countryCode || !number) {
    return ""
  }

  let prefix = "+"
  if (countryCode.includes("+")) {
    prefix = ""
  }
  return `${prefix}${countryCode}${number}`
}

export const buildFullName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName?: string | null
}) => [firstName, lastName].filter(Boolean).join(" ")

/**
 * @param setter setter to set errors in the form
 * @param fieldErrors errors from the API
 * @param fields Format: [BackendKey, FrontendKey] OR [key] (if both key names are same)
 */
export const mapErrors = <T extends FieldValues>(
  setter: UseFormSetError<T>,
  { fieldErrors }: ErrorResponse["errors"],
  fields: ([string, string] | [string])[]
) => {
  fields.forEach(pair => {
    if (!fieldErrors) return

    const key = pair[0]

    const message: string = Array.isArray(fieldErrors[key])
      ? fieldErrors[key][0]
      : (fieldErrors[key] as string)

    if (pair.length === 1) {
      if (message) {
        setter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          key as any,
          {
            message,
          },
          { shouldFocus: true }
        )
      }
    }
    if (pair.length === 2) {
      const frontendKey = pair[1]
      if (message) {
        setter(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          frontendKey as any,
          { message },
          { shouldFocus: true }
        )
      }
    }
  })
}

export const formatDate = (
  date: string | undefined,
  formatStr = "MMMM d, yyyy"
) => (date ? format(new Date(date), formatStr) : "")

export const getSSOUrl = (options?: { signup?: boolean; next?: string }) => {
  const signup = options?.signup || false

  if (options?.next) {
    const encoded = encodeURI(options.next)
    return getPlatformURL("sso", `?platform=Student&redirect-url=${encoded}`)
  }

  return typeof window === "undefined"
    ? getPlatformURL("learn", "/login")
    : getPlatformURL(
        "sso",
        `${signup ? "/signup/" : "/"}?platform=Student&redirect-url=${encodeURI(
          `${window.location.origin}/sso?next=${encodeURIComponent(
            window.location.pathname + window.location.search
          )}`
        )}`
      )
}

export const getMasterclassStatus = (masterclass: Masterclass | null) => {
  if (!masterclass) return null

  const startTime = new Date(masterclass.startTime)
  const currentTime = new Date()
  const endTime = add(startTime, {
    seconds: parseDuration(masterclass.duration),
  })

  if (startTime > currentTime) {
    return "upcoming"
  }
  if (currentTime > endTime) {
    return "completed"
  }

  return "ongoing"
}

export const downloadFileFromUrl = (href: string, fileName: string) => {
  const link = document.createElement("a")
  link.href = href
  link.download = fileName
  link.click()
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobLink = window.URL.createObjectURL(blob)
  downloadFileFromUrl(blobLink, name)
  setTimeout(() => {
    window.URL.revokeObjectURL(blobLink)
  }, 100)
}

type EventProps<T> = {
  event: Parameters<typeof GA.trackEvent>[0]
  params?: object
  res: APIResponse<T>
}

export const trackGAEventWithStatus = <T>({
  event,
  params,
  res,
}: EventProps<T>) => {
  const statusCode = res.isSuccessful ? res.__data?.status : res.statusCode
  GA.trackEvent(event, { ...params, status_code: statusCode })
}

export const getITOShareReferralText = (link: string) =>
  `Hello, I'll be participating in the International Teachers' Olympiad 2023. It's such a fantastic opportunity for us teachers to discover our hidden potential and get recognition for it!! 😍 Might be helpful for you too, do check out this link: ${link}`

export const ITODashboardLoginLink = getSSOUrl({
  next: `${process.env.NEXT_PUBLIC_LEARN_PLATFORM_URL}/ito`,
})

export const trackUser = (
  authInfo: {
    user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
  },
  options?: { timeout?: number }
) => {
  try {
    setTimeout(() => {
      trackingService.initUser(authInfo)
      GA.setUserId(authInfo.user.uuid)
    }, options?.timeout || 0)
  } catch (e) {
    console.error(e)
  }
}

export function stripHtml(str: string) {
  if (!str) return ""

  try {
    // Parse the string as HTML
    const doc = parseDocument(str)

    // Get the text content of the parsed HTML
    const textContent = DomUtils.textContent(doc)

    // Decode HTML entities
    const decodedText = decode(textContent)

    // Remove any extra spaces
    return decodedText.trim()
  } catch (e) {
    return ""
  }
}

export const MB_IN_BYTES = 1000000
export const MBToBytes = (mb: number) => mb * 1000000

export const triggerAppIntent = (path: string) =>
  `${SURAASA_LEARN_APP_SCHEME}://suraasa.com/${path}`

export const getITOCertificateShareText = (
  type: string,
  url: string,
  year?: 2022 | 2023
) => {
  year = year ?? 2022

  if (year === 2023 && type === ITOCertificateType.PARTICIPATION) {
    return `Glad to have attempted the International Teachers’ Olympiad 2023 by Suraasa. It was a truly enriching experience to test my pedagogy skills!😊

I look forward to keep growing and learning more!

Here is a memoir of my participation: ${url}

#TrueTeachingPotential #Suraasa`
  }

  let opener
  switch (type) {
    case ITOCertificateType.PARTICIPATION:
      opener =
        "Super happy to share that I am one of the highly motivated teachers globally."
      break
    case ITOCertificateType.EXCELLENCE:
      opener =
        "Super happy to share that I stand among the top 100 teachers globally."
      break
    case ITOCertificateType.MERIT_30_PERCENTILE:
      opener =
        "Super happy to share that I stand among the top 30% teachers globally."
      break
    case ITOCertificateType.MERIT_10_PERCENTILE:
      opener =
        "Super happy to share that I stand among the top 10% teachers globally."
      break
    case ITOCertificateType.MERIT_1_PERCENTILE:
      opener =
        "Super happy to share that I stand among the top 1% teachers globally."
      break
    default:
      break
  }

  return `${opener} Special thanks to the International Teachers’ Olympiad by Suraasa for giving all teachers such a wonderful opportunity of assessing their skills and getting celebrated for it. I am thrilled to proceed to the next level of growth in my teaching career and discover my #TrueTeachingPotential!\nCheck out my certificate: ${url}`
}
export const maskEmail = (email: string | undefined) => {
  if (!email) return ""

  const [pii, domain] = email.split("@")

  return [pii.slice(0, 2), "******", "@", domain].join("")
}

export const isLocalhost = process.env.NODE_ENV === "development"

export function sleep(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
